

.vendor-chat-widget {
  &.width-expand {
    max-width: calc(100lvw - 2 * var(--margin-2xl));
  }
  &.active {
    border: 1px solid var(--c-gray);
    background: var(--c-gray-1);

  }
}
.cta-block {
  background-color: var(--c-lead);
  opacity: 1;
  will-change: opacity;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
}
