
.chat-message {
  max-width: 88%;
  margin-inline-end: var(--margin-m);
  margin-top: var(--margin-l);
  margin-inline-start: auto;

  &.from-self {
    align-self: flex-end;
    margin-inline-end: auto;
    margin-inline-start: var(--margin-m);
  }

  @if (mixin-exists(hook-chat-message)) {
    @include hook-chat-message();
  }
}

.chat-message-wrapper {
  margin-top: var(--margin-xl);

  &.from-self {
    .message-blob {
      //flex-direction: row-reverse;
    }
  }

  &:not(.from-self) {
    .message-blob {
      flex-direction: row-reverse;
    }
  }

  .user-avatar {
    //  margin-top: var(--margim-l);
  }

  .chat-message {
    position: relative;
    margin: var(--margin-m);
    flex: 100%;
    border: 1px solid var(--c-gray-2);


    .visibility-ui {
      bottom: 100%;
      margin-bottom: -0.75em;
      inset-inline-start: -0.75em;
      border-radius: 100%;
      background-color: var(--c-bg-4);
      border: 1px solid var(--c-gray-2);
      padding: var(--margin-s);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: var(--z-level-1);
      height: 2em;
      width: 2em;
      text-align: center;
      line-height: 100%;

      .icon {
        position: relative;
        top: 0.15rem;

      }
      .first-icon {
        position: relative;
        inset-inline-start: -0.25em;
      }
      .second-icon {
        position: absolute;
        top: 50%;
        margin-top: -50%;
        inset-inline-start: 50%;
        margin-inline-start: calc(-50% );
      }
      &.seen-by-all {

      }
    }

    .role-ui {
      top: 100%;
      margin-top: -0.75em;
      inset-inline-end: 1em;
      border-radius: var(--border-radius-s);
      background-color: var(--c-bg-4);
      border: 1px solid var(--c-gray-2);
      padding: var(--margin-s) var(--margin-m);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: var(--z-level-1);
      min-height: 2em;

      text-align: center;
      line-height: 100%;
    }
  }
}
