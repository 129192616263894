
.messages {
  flex: 100%;
  padding-bottom: var(--margin-l);
}

.actions {
  border-top: 1px solid var(--c-gray-2);
  padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
}
